.footer {
  background-color: #000;
  padding: 80px 0;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.footerWrapper::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: white;
      position: absolute;
      top: 250px;
}

.wrapperFirst,
.footerList,
.thirdWrapper {
  width: 32%;
}

.wrapperFirst {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.wrapperFirst p {
  font-size: 20.49px;
  font-weight: 400;
  line-height: 24.22px;
}

.wrapperFirst a {
  cursor: pointer;
}

.footerList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footerList li {
  width: 48%;
  font-size: 26.92px;
  font-weight: 400;
  line-height: 31.81px;
  font-style: italic;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.footerList a {
  cursor: pointer;
  font-style: italic;
}

.thirdWrapper h4 {
  font-size: 26.92px;
  font-weight: 400;
  line-height: 31.81px;
  margin-bottom: 10px;
}

.thirdWrapper p {
  font-size: 16.43px;
  font-style: italic;
  font-weight: 400;
  line-height: 19.42px;
  margin-bottom: 20px;
}

.footerForm {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid green;
  display: flex;
  justify-content: space-between;
}

.footerForm input {
  width: 65%;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 25.31px;
  outline: none;
  border: none;
}

.footerForm button {
  width: 35%;
  padding: 10px 0;
  background: #b9ff66;
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 26.94px;
}

.footerText {
      margin-top: 60px;
}
