.form {
  padding: 30px 50px;
  background-color: #141414;
}

.formTitle {
  font-size: 45.41px;
  font-style: italic;
  font-weight: 400;
  line-height: 53.67px;
  margin-bottom: 30px;
}

.inputsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
  margin-bottom: 40px;
}

.inputsWrapper input {
  width: 48%;
  background: #000000;
  padding: 16px 20px;
  outline: none;
  border: none;
  font-size: 20.68px;
  font-style: italic;
  font-weight: 400;
  line-height: 24.44px;
}

.inputsWrapper textarea {
  width: 100%;
  height: 150px;
  background: #000000;
  padding: 12px 20px;
  font-size: 20.68px;
  font-style: italic;
  font-weight: 400;
  line-height: 24.44px;
  outline: none;
  border: none;
  resize: none;
}

.formBtn {
  padding: 12px 60px;
  border: none;
  background: #b9ff66;
  font-size: 20.68px;
  font-style: italic;
  font-weight: 400;
  line-height: 24.44px;
  text-align: center;
  display: block;
  margin: auto;
}
