.visionTitle {
      font-size: 80.83px;
      font-style: italic;
      font-weight: 400;
      line-height: 95.54px;
      text-align: center;
      padding: 2.5rem 0;
      margin-bottom: 2.5rem;
      position: relative;
}

.visionTitle::after {
      content: url("../../assets/line.png");
      position: absolute;
      top: 60px;
      left: 33.3%;

}

.visionWrapper {
      width: 100%;
      padding-bottom: 80px;
      display: flex;
      justify-content: space-between;
}

.visionWrapper div {
      width: 48%;
}

.imgList {
      position: relative;
      display: flex;
      align-items: end;
      justify-content: space-between;
}

.imgList img {
      object-fit: contain;
}

.rectPosition {
      position: absolute;
      top: 1.5rem;
      left: 24.5rem;
}

.infoTitle { 
      font-size: 45.93px;
      font-weight: 400;
      line-height: 54.29px;
      text-align: left;
      width: 50%;
      margin-bottom: 1rem;
}

.infoText {
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      line-height: 31.91px;
      text-align: left;
}