@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'ABeeZee', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

