@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap");

.processList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 150px;
      position: relative;

}

.processList::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: white;
      position: absolute;
      top: 120px;
}

.left , .center , .right {
      position: absolute;
      top: 120px;
}

.left {
      left: 160px;
}

.center {
      left: 570px;
}

.right {
      right: 160px;
}

.procesTitle {
  font-size: 80.83px;
  font-style: italic;
  font-weight: 400;
  line-height: 95.54px;
  text-align: center;
  position: relative;
  margin-bottom: 150px;
}

.procesTitle::after {
  content: url("../../assets/lineProcess.png");
  position: absolute;
  left: 100px;
  top: 40px;
}

.processItem {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 100px;
}

.processText {
  font-family: "ABeeZee", sans-serif !important;
  font-style: italic;
  -webkit-text-stroke: 2px white; /* width and color */
  color: transparent;
  font-size: 102.96px;
  font-style: italic;
  font-weight: 400;
  line-height: 121.69px;
  text-align: center;
}

.processTextInfo {
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: 35.46px;
  text-align: center;
  margin-top: 15px;
}
