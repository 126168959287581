.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 80px;
}

.navbar a {
  cursor: pointer;
}

.navbar button {
  cursor: pointer;
}

.navbarList {
  display: flex;
  align-items: center;
  gap: 80px;
  font-style: italic;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}

.navbarList li {
  cursor: pointer;
}

.navbarButton {
  padding: 10px 30px;
  border: none;
  background-color: transparent;
  border: 1px solid #6cc800;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5;
}
